import React from "react";
import SEO from "@components/common/seo";
import Layout from "@components/layout";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import bonus from "@components/images/baji999/baji999-bonus.webp";
import soccer from "@components/images/baji999/baji999-soccer.webp";
import basketball from "@components/images/baji999/baji999-basketball.webp";
import kabaddi from "@components/images/baji999/baji999-kabaddi.webp";
import cricket from "@components/images/baji999/baji999-cricket.webp";
import horseRacing from "@components/images/baji999/baji999-horse-racing.webp";
import virtualSports from "@components/images/baji999/baji999-virtual-sports.webp";
import banner2 from "@components/images/baji999/baji999-1536x431.webp";

import registrationBonus from "@components/images/baji999/baji999-registration-bonus.webp";
import firstDeposit from "@components/images/baji999/baji999-bonus-for-first-deposit.webp";
import activityBonus from "@components/images/baji999/baji999-gaming-activity-bonus.webp";
import refferalBonus from "@components/images/baji999/baji999-refferal-bonus.webp";
import topUp from "@components/images/baji999/baji999-top-up.webp";
import reloadBonus from "@components/images/baji999/baji999-reload-bonus.webp";
import cashback from "@components/images/baji999/baji999-cashback.webp";

import leaderboard from "@components/images/baji999/baji999-seasonal-leaderboard.webp";
import VIPPoints from "@components/images/baji999/baji999-VIP-points.webp";
import VIPManager from "@components/images/baji999/baji999-VIP-manager.webp";
import VIPRewards from "@components/images/baji999/baji999-VIP-exclusive-rewards.webp";
import VIPPayment from "@components/images/baji999/baji999-VIP-payment-channel.webp";

import register from "@components/images/baji999/baji999-register.webp";
import slots from "@components/images/baji999/baji999-slots.webp";
import tableGames from "@components/images/baji999/baji999-table-games.webp";
import jackpots from "@components/images/baji999/baji999-jackpots.webp";
import fishing from "@components/images/baji999/baji999-fishing.webp";
import liveDealer from "@components/images/baji999/baji999-live-dealer-casino.webp";

import app from "@components/images/baji999/baji999-application.webp";

import withLocation from "@helpers/hoc/withLocation";

const Baji999 = ({ location }: any) => {
  return (
    <Layout location={location.pathname}>
      <div className="bg-[white]">
        <SEO
          title="বাজি লাইভ ক্রিকেট | বাজি লাইভ ৯৯৯"
          description="Baji999 হল বাংলাদেশে ভিত্তিক একটি গেমিং প্ল্যাটফর্ম, যেটি শুধুমাত্র সেই দেশেই নয়, প্রায় সমগ্র দক্ষিণ পূর্ব এশিয়া এবং ভারতে গেমারদের মধ্যে ক্রমশ জনপ্রিয় হয়ে উঠছে।"
        />

        <div className="bg-[url('@components/images/baji999/bg1.webp')] bg-no-repeat bg-cover">
          <div className="content-container p-[15px]">
            <h2 className="text-2xl text-center text-white">
              Baji999 লাইভ - ক্যাসিনো এবং স্পোর্টস বেটিং
            </h2>
            <div className="grid items-center md:grid-cols-2">
              <div className="flex flex-col items-center">
                <img src={bonus} alt="bonus" className="w-[350px] mb-0" />
                <h3 className="my-2 text-lg italic font-medium text-white md:text-2xl">
                  Welcome Offer - 100% First Deposit Bonus
                </h3>
                <button className="flex items-center justify-center bg-gradient-to-r from-[#f69501] to-[#fbc004] rounded-[5px] text-lg h-[45px] px-[24px] text-white my-3">
                  বোনাস পিক আপ করুন
                </button>
              </div>
              <p className="text-sm text-center text-white md:text-lg">
                <span className="font-bold">Baji999</span> হল বাংলাদেশে ভিত্তিক
                একটি গেমিং প্ল্যাটফর্ম, যেটি শুধুমাত্র সেই দেশেই নয়, প্রায়
                সমগ্র দক্ষিণ পূর্ব এশিয়া এবং ভারতে গেমারদের মধ্যে ক্রমশ
                জনপ্রিয় হয়ে উঠছে। এটি খেলোয়াড়দের জন্য একটি উত্তেজনাপূর্ণ
                অ্যারে গেম এবং বৈশিষ্ট্য অফার করে যারা তাদের দক্ষতা পরীক্ষা করতে
                এবং নিজেদের চ্যালেঞ্জ করতে চায়। দাবা এবং মাহজং-এর মতো ক্লাসিক
                বোর্ড গেম থেকে শুরু করে ভার্চুয়াল জগতের রোমাঞ্চকর অ্যাডভেঞ্চার
                পর্যন্ত, Baji999-এ প্রত্যেকের জন্য কিছু না কিছু আছে। গ্রাফিক্স
                অত্যাশ্চর্য বাস্তবসম্মত, শব্দ প্রভাব বাস্তবসম্মত এবং গেমপ্লে
                মসৃণ এবং উপভোগ্য।
              </p>
            </div>
          </div>
        </div>

        <div className="bg-[#353535]">
          <div className="content-container md:px-[15px]">
            <Accordion className="!bg-[#353535] md:w-[70%] mx-auto !rounded-none !shadow-none">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="text-[#fff]" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="!border-b-[1px] !border-[#fff] !border-solid"
              >
                <h2 className="text-[#fff]">বিষয়বস্তু</h2>
              </AccordionSummary>
              <AccordionDetails>
                <ul className="list-item text-[#fff]">
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      Baji999 রিভিউ
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      বোনাস এবং প্রচার
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      ভিআইপি-ক্লাবregistration
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      রেজিস্ট্রেশন প্রক্রিয়া
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      সুবিধাগুলি এবং অসুবিধাগুলি
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      অনলাইন ক্যাসিনো
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      ক্রীড়া পণ
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      আবেদন
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      জমা পদ্ধতি
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      নিরাপত্তা
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      প্রযুক্তিগত গ্রাহক পরিষেবা
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      প্রায়শই জিজ্ঞাসিত প্রশ্নাবলী
                    </a>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>

            <div className="bg-[#484848] mt-4 py-4">
              <div className="grid grid-cols-3">
                <div>
                  <img
                    className="mb-0 w-[70%] mx-auto duration-[.3s] hover:scale-[.9]"
                    src={soccer}
                    alt="সকার"
                  />
                  <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                    সকার
                  </p>
                </div>
                <div>
                  <img
                    className="mb-0 w-[70%] mx-auto duration-[.3s] hover:scale-[.9]"
                    src={basketball}
                    alt="বাস্কেটবল"
                  />
                  <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                    বাস্কেটবল
                  </p>
                </div>
                <div>
                  <img
                    className="mb-0 w-[70%] mx-auto duration-[.3s] hover:scale-[.9]"
                    src={kabaddi}
                    alt="কাবাডি"
                  />
                  <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                    কাবাডি
                  </p>
                </div>
                <div>
                  <img
                    className="mb-0 w-[70%] mx-auto duration-[.3s] hover:scale-[.9]"
                    src={cricket}
                    alt="ক্রিকেট"
                  />
                  <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                    ক্রিকেট
                  </p>
                </div>
                <div>
                  <img
                    className="mb-0 w-[70%] mx-auto duration-[.3s] hover:scale-[.9]"
                    src={horseRacing}
                    alt="ঘোড়দৌড়"
                  />
                  <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                    ঘোড়দৌড়
                  </p>
                </div>
                <div>
                  <img
                    className="mb-0 w-[70%] mx-auto duration-[.3s] hover:scale-[.9]"
                    src={virtualSports}
                    alt="ভার্চুয়াল স্পোর্টস"
                  />
                  <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                    ভার্চুয়াল স্পোর্টস
                  </p>
                </div>
              </div>

              <div className="border-y-[5px] border-[#353535] mt-5 py-5 mx-3">
                <h2 className="mb-3 text-2xl text-center text-white">
                  Baji 999 পর্যালোচনা
                </h2>
                <p className="mb-4 text-sm text-white md:text-lg">
                  একটি গেমিং প্ল্যাটফর্ম যা তার গ্রাহকদের জন্য বিভিন্ন ধরনের গেম
                  অফার করে। প্ল্যাটফর্মটিতে অ্যাডভেঞ্চার এবং ফাইটিং গেম থেকে
                  শুরু করে ক্লাসিক বোর্ড গেমস, খেলাধুলায় বাজি, সাইবার স্পোর্টস
                  এবং আরও অনেক কিছু রয়েছে। এতে মাল্টিপ্লেয়ার বিকল্পগুলির একটি
                  বড় নির্বাচনও রয়েছে, যাতে গেমাররা রিয়েল টাইমে একে অপরের
                  বিরুদ্ধে প্রতিদ্বন্দ্বিতা করতে পারে।
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  গেমের গ্রাফিক্স বাস্তবসম্মত এবং তরল, গেমপ্লেটিকে অত্যন্ত
                  নিমগ্ন করে তোলে। সহজ কিন্তু কার্যকর নিয়ন্ত্রণের জন্য ধন্যবাদ,
                  খেলোয়াড়রা সহজেই বিভিন্ন স্তরের মাধ্যমে কৌশল করতে পারে।
                  এছাড়াও, ব্যাকগ্রাউন্ড মিউজিকটিও মনোরম এবং প্রশান্তিদায়ক, যা
                  আপনার প্রিয় গেমটি খেলার সময় পরিবেশে যোগ করে।
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  সামগ্রিকভাবে, Baji999 একটি দুর্দান্ত প্ল্যাটফর্ম যা লোকেদের
                  তাদের নিজের ঘরে বসেই উত্তেজনাপূর্ণ গেম উপভোগ করতে দেয়। এটি
                  ব্যবহার করা সহজ, গেমগুলির একটি বিস্তৃত নির্বাচন রয়েছে এবং
                  একটি দুর্দান্ত মাল্টিপ্লেয়ার অভিজ্ঞতা প্রদান করে। সব গেমারদের
                  জন্য অত্যন্ত সুপারিশ!
                </p>
                <img className="mb-0" src={banner2} alt="banner" />
              </div>

              <div className="border-b-[5px] border-[#353535] mt-5 pb-5 mx-3">
                <table className="w-[100%]">
                  <thead className="">
                    <tr>
                      <th className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        দাপ্তরিক নাম
                      </th>
                      <th className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        BAJI999
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        লাইসেন্স
                      </td>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        কুরাকাওao
                      </td>
                    </tr>
                    <tr>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        প্রতিষ্ঠার বছর
                      </td>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        2021
                      </td>
                    </tr>
                    <tr>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        খেলাধুলার সংখ্যা
                      </td>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        20+
                      </td>
                    </tr>
                    <tr>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        মোবাইল অ্যাপ্লিকেশন
                      </td>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        অ্যান্ড্রয়েড
                      </td>
                    </tr>
                    <tr>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        সমর্থন
                      </td>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        24/7
                      </td>
                    </tr>
                    <tr>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        গ্রাহক সমর্থন
                      </td>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        ইমেল, লাইভ চ্যাট এবং সোশ্যাল মিডিয়াe chat and social
                        media
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="border-b-[5px] border-[#353535] py-5 mx-3">
                <h2 className="mb-3 text-2xl text-center text-white">
                  বোনাস & প্রচার
                </h2>
                <p className="mb-4 text-sm text-white md:text-lg">
                  Casino Baji.999 নগদ বোনাস, ফ্রিস্পিন এবং কমপস উভয় নতুন
                  ব্যবহারকারী এবং নিয়মিত গ্রাহকদের জন্য অফার করে। এটি গেমারদের
                  জুয়া প্রতিষ্ঠানে অভ্যস্ত হতে এবং কার্যত কোন খরচ ছাড়াই
                  সক্রিয়ভাবে জুয়া খেলার অনুমতি দেয়। সাইটটি বিভিন্ন ধরণের
                  বোনাস প্রদান করে। নিয়মিতভাবে এবং স্বল্পমেয়াদী প্রচারের অংশ
                  হিসাবে ক্লাব সদস্যদের বোনাস দেওয়া হয়। Baji999 নিম্নলিখিত
                  প্রধান বোনাস অফার করে:
                </p>
                <div className="flex flex-wrap">
                  <div className="flex-grow-1 w-[50%] md:w-[25%] !p-4">
                    <img
                      className="mb-2 w-[30%] md:w-[25%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={registrationBonus}
                      alt="রেজিস্ট্রেশনের জন্য"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      রেজিস্ট্রেশনের জন্য
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] md:w-[25%] !p-4">
                    <img
                      className="mb-2 w-[30%] md:w-[25%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={firstDeposit}
                      alt="প্রথম জমার জন্য"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      প্রথম জমার জন্য
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] md:w-[25%] !p-4">
                    <img
                      className="mb-2 w-[30%] md:w-[25%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={activityBonus}
                      alt="গেমিং কার্যকলাপের জন্য"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      গেমিং কার্যকলাপের জন্য
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] md:w-[25%] !p-4">
                    <img
                      className="mb-2 w-[30%] md:w-[25%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={refferalBonus}
                      alt="রেফারেল বোনাস"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      রেফারেল বোনাস
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] md:w-[25%] !p-4">
                    <img
                      className="mb-2 w-[30%] md:w-[16%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={topUp}
                      alt="একটি নির্দিষ্ট পরিমাণ সঙ্গে টপ আপ"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      একটি নির্দিষ্ট পরিমাণ সঙ্গে টপ আপ
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] md:w-[25%] !p-4">
                    <img
                      className="mb-2 w-[30%] md:w-[16%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={reloadBonus}
                      alt="10,000 BDT পর্যন্ত বোনাস পুনরায় লোড করুন"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      10,000 BDT পর্যন্ত বোনাস পুনরায় লোড করুন
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] md:w-[25%] !p-4">
                    <img
                      className="mb-2 w-[20%] md:w-[16%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={cashback}
                      alt="নগদ ফেরত"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      নগদ ফেরত
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-[#000]">
              <div className="!py-5 mx-3">
                <h2 className="mb-3 text-2xl text-center text-white">
                  ভিআইপি-ক্লাব
                </h2>
                <div className="flex flex-wrap justify-center">
                  <img
                    className="mb-2 p-[10px] md:w-[33%] mx-auto duration-[.3s] hover:scale-[.9]"
                    src={leaderboard}
                    alt="leaderboard"
                  />
                  <img
                    className="mb-2 p-[10px] md:w-[33%] mx-auto duration-[.3s] hover:scale-[.9]"
                    src={VIPPoints}
                    alt="VIP points"
                  />
                  <img
                    className="mb-2 p-[10px] md:w-[33%] mx-auto duration-[.3s] hover:scale-[.9]"
                    src={VIPManager}
                    alt="VIP manager"
                  />
                  <img
                    className="mb-2 p-[10px] md:w-[33%] duration-[.3s] hover:scale-[.9]"
                    src={VIPRewards}
                    alt="VIP rewards"
                  />
                  <img
                    className="mb-2 p-[10px] md:w-[33%] duration-[.3s] hover:scale-[.9]"
                    src={VIPPayment}
                    alt="VIP payment"
                  />
                </div>
                <p className="mb-4 text-sm text-white md:text-lg">
                  Baji999 VIP ক্লাব হল গেমারদের জন্য চূড়ান্ত গেমিং অভিজ্ঞতা
                  যারা তাদের গেমিংকে পরবর্তী স্তরে নিয়ে যেতে চায়। ভিআইপি
                  ক্লাবের একজন সদস্য হিসাবে, আপনি একচেটিয়া পুরষ্কার এবং
                  বিশেষাধিকারগুলিতে অ্যাক্সেস পাবেন যা শুধুমাত্র সদস্যদের জন্য
                  উপলব্ধ। বিশেষ ছাড়, একচেটিয়া টুর্নামেন্ট এবং প্রতিযোগিতা এবং
                  আরও অনেক কিছুর সাথে, গেমারদের এই অভিজাত গ্রুপের অংশ হওয়া
                  সত্যিই একটি অবিস্মরণীয় অভিজ্ঞতা হবে।
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  একজন ভিআইপি ক্লাব সদস্য হিসাবে, আপনি Baji999 এর মাধ্যমে করা
                  সমস্ত কেনাকাটার উপর বিশেষ ছাড় পাবেন। আপনি গেমের সময় বা
                  ক্রেডিটগুলির মতো মাঝে মাঝে বিনামূল্যের পাশাপাশি অন্য কারও আগে
                  সমস্ত আসন্ন ইভেন্টগুলিতে অগ্রাধিকার অ্যাক্সেস পাবেন। উপরন্তু,
                  ভিআইপি সদস্যরা একচেটিয়া টুর্নামেন্টে অংশগ্রহণ করতে পারে
                  যেখানে তারা গেমিং হার্ডওয়্যার এবং উপহার কার্ডের মতো পুরস্কার
                  জিততে পারে।
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  এছাড়াও আপনি ভিআইপি ক্লাবের সাথে একটি প্রাণবন্ত এবং উত্সাহী
                  গেমিং সম্প্রদায়ের অংশ হবেন। আপনার কাছে এক্সক্লুসিভ ফোরাম,
                  চ্যাট এবং আরও অনেক কিছুর অ্যাক্সেস থাকবে যাতে আপনি সহ গেমারদের
                  সাথে কথা বলতে পারেন এবং কীভাবে আপনার প্রিয় গেমগুলিতে এক্সেল
                  করবেন সে সম্পর্কে টিপস এবং কৌশলগুলি ভাগ করতে পারেন৷ এবং যদি তা
                  যথেষ্ট না হয়, VIP সদস্যরা শুধুমাত্র ক্লাবের অংশ হওয়ার জন্য
                  প্রতি মাসে একচেটিয়া ইন-গেম পুরষ্কার পাবেন।
                </p>
              </div>
            </div>

            <div className="bg-[#484848] py-4">
              <div className="border-y-[5px] border-[#353535] py-4 mx-3">
                <h2 className="mb-3 text-2xl text-center text-white">
                  Baji999 রেজিস্ট্রেশন প্রক্রিয়া
                </h2>
                <p className="mb-4 text-sm text-white md:text-lg">
                  Baji999 অনলাইন গেমিং প্ল্যাটফর্মে যে কেউ নিবন্ধন করতে পারেন।
                  প্রধান বিষয় হল যে তাকে আইনি বয়স হতে হবে। অপ্রাপ্তবয়স্ক বলে
                  পাওয়া যে কোনো প্রোফাইল অপরিবর্তনীয়ভাবে ব্লক করা হবে। একই
                  ভাগ্য তাদের জন্য অপেক্ষা করছে যারা একাধিক ব্যক্তিগত এলাকা তৈরি
                  করার চেষ্টা করে। কিছু ক্ষেত্রে সব পণ্য ব্লক করা হবে.
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  একটি প্রোফাইল তৈরি করতে, স্ট্যান্ডার্ড ইলেকট্রনিক ফর্ম ব্যবহার
                  করুন। ব্যবহারকারীদের অ্যাপে বা মূল সংস্থান পৃষ্ঠায় “সাইন আপ”
                  বোতামটি খুঁজে বের করতে হবে এবং ইলেকট্রনিক ফর্মটি পূরণ করতে
                  হবে।
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  Baji999 এর জন্য আপনার প্রয়োজন:
                </p>
                <ul className="list-item text-[#fff]">
                  <li className="text-sm list-disc md:text-lg">
                    একটি ব্যবহারকারীর নাম লিখুন;
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    একটি শক্তিশালী পাসওয়ার্ড চিন্তা করুন;
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    আপনার পছন্দের মুদ্রা চয়ন করুন (BDT, INR বা PKR);
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    যদি একটি রেফার কোড থাকে তবে এটি ব্যবহার করতে ভুলবেন না;
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    তারপরে, রেজিস্ট্রেশনের অন্য পর্যায়ে গিয়ে, আপনাকে পুরো
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    নাম, ই-মেইল এবং ফোন নম্বরের মতো ডেটা পূরণ করতে হবে;
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    আপনাকে আপনার বয়স নিশ্চিত করতে হবে এবং আপনি আপনার নিবন্ধন
                    সম্পূর্ণ করতে পারবেন।
                  </li>
                </ul>
                <button className="flex items-center justify-center mx-auto bg-gradient-to-r from-[#f69501] to-[#fbc004] rounded-[5px] text-lg h-[45px] px-[24px] text-white mt-3">
                  এখন নিবন্ধন করুন
                </button>
                <img
                  className="my-3 w-[60%] mx-auto"
                  src={register}
                  alt="register"
                />

                <h2 className="mb-3 text-2xl text-center text-white">
                  Baji999 লগইন করুন
                </h2>
                <p className="mb-4 text-sm text-white md:text-lg">
                  আপনার গেমিং প্ল্যাটফর্ম অ্যাকাউন্টে লগ ইন করা আপনাকে সমস্ত গেম
                  এবং সামগ্রীর উপর নজর রাখতে দেয় যা আপনার অ্যাক্সেস আছে। এটি
                  আপনার পুরষ্কার দেখা, অতিরিক্ত সামগ্রী কেনা এবং আপনার প্রোফাইল
                  তথ্যে পরিবর্তন করা সহজ করে তোলে।
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  আপনার ব্যক্তিগত অ্যাকাউন্টে লগ ইন করতে, ওয়েবসাইটের যেকোনো
                  পৃষ্ঠার উপরের ডানদিকে অবস্থিত ‘লগইন’ লিঙ্কটিতে ক্লিক করুন।
                  তারপরে আপনাকে আপনার ব্যবহারকারীর নাম এবং পাসওয়ার্ড লিখতে
                  অনুরোধ করা হবে। এই তথ্যটি প্রবেশ করার পরে, আপনার গেমিং
                  অ্যাকাউন্টের মধ্যে উপলব্ধ সমস্ত বৈশিষ্ট্যগুলিতে অ্যাক্সেস পেতে
                  ‘লগইন’ এ ক্লিক করুন৷
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  আপনার যদি লগ ইন করতে সমস্যা হয় বা সাইটের কোনো দিক দিয়ে
                  সহায়তার প্রয়োজন হয়, অনুগ্রহ করে আরও সহায়তার জন্য আমাদের
                  গ্রাহক পরিষেবা দলের সাথে যোগাযোগ করুন। আপনার সম্ভাব্য সেরা
                  গেমিং অভিজ্ঞতা নিশ্চিত করতে আমরা এখানে আছি!
                </p>

                <h2 className="mt-5 mb-3 text-2xl text-center text-white">
                  অ্যাকাউন্ট পরীক্ষা
                </h2>
                <p className="mb-4 text-sm text-white md:text-lg">
                  অ্যাকাউন্ট যাচাইকরণ গেমিং প্ল্যাটফর্মের একটি গুরুত্বপূর্ণ অংশ।
                  এটি খেলোয়াড়দের নিরাপত্তা নিশ্চিত করতে সাহায্য করে, সেইসাথে
                  সমস্ত লেনদেনের বৈধতা নিশ্চিত করে। ব্যবহারকারীর অ্যাকাউন্ট
                  যাচাই করে, Baji999 নিশ্চিত করে যে তার প্ল্যাটফর্মে খেলা যে
                  কোনও ব্যক্তি আইনি বয়সের এবং সম্মত শর্তাবলী অনুযায়ী কাজ করতে
                  পারে।
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  অ্যাকাউন্ট ভেরিফিকেশনে ব্যক্তিগত পরিচয়পত্র যেমন পাসপোর্ট,
                  ড্রাইভিং লাইসেন্স ইত্যাদি প্রদান করা জড়িত।
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  যাচাইকরণ প্রক্রিয়াটি তহবিল স্থানান্তর করার সময় জালিয়াতি বা
                  চুরি থেকে ব্যবহারকারীদের রক্ষা করার জন্য ডিজাইন করা হয়েছে।
                  অ্যাকাউন্টটি যাচাই করার মাধ্যমে, Baji999 নিশ্চিত করতে পারে যে
                  ব্যবহারকারী আসলেই তিনি যাকে তারা বলছেন এবং তাদের কার্ডের বিশদ
                  বিবরণ এবং ব্যাঙ্কিং তথ্য চুরি বা আপোস করা হয়নি। এটি নিশ্চিত
                  করে যে সমস্ত অর্থ স্থানান্তর নিরাপদ এবং অনুপ্রবেশকারীদের থেকে
                  সুরক্ষিত।
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  এছাড়াও, যাচাইকরণ প্রক্রিয়া খেলোয়াড়দের বিভিন্ন নামে একাধিক
                  অ্যাকাউন্ট তৈরি করা বা প্ল্যাটফর্মে গেমগুলিতে অংশগ্রহণের জন্য
                  মিথ্যা নাম ব্যবহার করে তাদের দায়বদ্ধ রাখতে সহায়তা করে।
                  Baji999 সর্বদা তার ব্যবহারকারীদের সুরক্ষার জন্য
                  প্রতিশ্রুতিবদ্ধ এবং এর প্ল্যাটফর্মে খেলার সময় তাদের সুরক্ষা
                  এবং সুরক্ষা নিশ্চিত করার জন্য প্রয়োজনীয় সমস্ত ব্যবস্থা গ্রহণ
                  করে।
                </p>
              </div>

              <div className="border-b-[5px] border-[#353535] pb-4 mt-4 mx-3">
                <h2 className="mb-3 text-2xl text-center text-white">
                  Baji99 গেমিং প্লাটফর্মের সুবিধা ও অসুবিধা
                </h2>
                <div className="md:flex">
                  <table className="md:w-[50%] md:m-3">
                    <thead className="">
                      <tr>
                        <th className="border-[2px] text-center border-[#767676] w-[50%] uppercase p-3 text-sm md:text-lg text-white">
                          সুবিধাদি
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border-[2px] border-[#767676] w-[50%] uppercase p-3 text-sm md:text-lg text-white">
                          উদার বোনাস প্রচার
                        </td>
                      </tr>
                      <tr>
                        <td className="border-[2px] border-[#767676] w-[50%] uppercase p-3 text-sm md:text-lg text-white">
                          গেমের চিত্তাকর্ষক পরিসীমা
                        </td>
                      </tr>
                      <tr>
                        <td className="border-[2px] border-[#767676] w-[50%] uppercase p-3 text-sm md:text-lg text-white">
                          স্লট মেশিনে রিটার্নের উচ্চ শতাংশ
                        </td>
                      </tr>
                      <tr>
                        <td className="border-[2px] border-[#767676] w-[50%] uppercase p-3 text-sm md:text-lg text-white">
                          নিরাপত্তার চমৎকার স্তর
                        </td>
                      </tr>
                      <tr>
                        <td className="border-[2px] border-[#767676] w-[50%] uppercase p-3 text-sm md:text-lg text-white">
                          লাইসেন্স
                        </td>
                      </tr>
                      <tr>
                        <td className="border-[2px] border-[#767676] w-[50%] uppercase p-3 text-sm md:text-lg text-white">
                          15-30 মিনিট থেকে প্রক্রিয়াকরণের সময়
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="md:w-[50%] mt-3 md:m-3 h-[90px]">
                    <thead className="">
                      <tr>
                        <th className="border-[2px] text-center border-[#767676] w-[50%] uppercase p-3 text-sm md:text-lg text-white">
                          অসুবিধা
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border-[2px] border-[#767676] w-[50%] uppercase p-3 text-sm md:text-lg text-white">
                          সীমিত পেমেন্ট অপশন
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="border-b-[5px] border-[#353535] py-5 mx-3">
                <h2 className="mb-3 text-2xl text-center text-white">
                  অনলাইন ক্যাসিনো পরিসীমা
                </h2>
                <p className="mb-4 text-sm text-white md:text-lg">
                  অনলাইন ক্যাসিনো সেরা জুয়া মেশিনের একটি নির্বাচন বৈশিষ্ট্য.
                  সমস্ত সফ্টওয়্যার 20 টিরও বেশি প্রদানকারী দ্বারা সরবরাহ করা
                  হয়। ইলেকট্রনিক ক্যাটালগের স্লট এবং স্লট মেশিনগুলি সাবধানে
                  নির্বাচিত এবং স্থিতিশীল সফ্টওয়্যার লাইসেন্সপ্রাপ্ত। এটাকে
                  আউটস্মার্ট করা, হ্যাক করা বা টেম্পার করা যাবে না। জেতার
                  ক্ষেত্রে পেআউট শুধুমাত্র পেআউট শতাংশ দ্বারা প্রভাবিত হয়। একটি
                  স্থিতিশীল র্যান্ডম নম্বর জেনারেটর দ্বারা সরবরাহিত ভার্চুয়াল
                  সিমুলেটরগুলির অখণ্ডতা।
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  অনলাইন সাইটের ক্যাটালগে দেখা করতে পারে:
                </p>
                <div className="flex flex-wrap mx-3">
                  <div className="flex-grow-1 w-[50%] !p-4">
                    <img
                      className="mb-2 w-[50%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={slots}
                      alt="স্লট"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      স্লট
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] !p-4">
                    <img
                      className="mb-2 w-[50%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={tableGames}
                      alt="টেবিল গেম"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      টেবিল গেম
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] !p-4">
                    <img
                      className="mb-2 w-[50%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={jackpots}
                      alt="জ্যাকপটস"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      জ্যাকপটস
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] !p-4">
                    <img
                      className="mb-2 w-[50%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={fishing}
                      alt="মাছ ধরা"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      মাছ ধরা
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] !p-4">
                    <img
                      className="mb-2 w-[20%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={liveDealer}
                      alt="ডিলার"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      ডিলার
                    </p>
                  </div>
                </div>

                <div className="grid-cols-2 md:grid">
                  <div className="md:p-3">
                    <h2 className="mb-3 text-center md:!text-left text-2xl text-white">
                      স্লট
                    </h2>
                    <p className="mb-0 text-sm text-white md:text-lg">
                      Baji999-এর স্লট হল ক্যাসিনোতে সবচেয়ে বেশি খেলা এবং
                      জনপ্রিয় বিভাগ। তারা উত্তেজনাপূর্ণ এবং ফলপ্রসূ গেমিং
                      অভিজ্ঞতার একটি অতুলনীয় অ্যারের অফার করে। ক্লাসিক 3-রিল
                      থেকে আধুনিক 5-রিল ভিডিও স্লট পর্যন্ত স্লটের একটি বিশাল
                      নির্বাচনের সাথে, প্রত্যেকে নিজের জন্য কিছু খুঁজে পাবে।
                      সমস্ত গেম আপনার গেমিং অভিজ্ঞতাকে সত্যিই মশলাদার করার জন্য
                      অত্যাধুনিক গ্রাফিক্স, মসৃণ অ্যানিমেশন এবং সাউন্ড ইফেক্টের
                      পাশাপাশি উত্তেজনাপূর্ণ বোনাস রাউন্ড, ফ্রি স্পিন, জোকার এবং
                      স্ক্যাটার সিম্বল নিয়ে গর্ব করে। গেম খেলুন যেমন:
                    </p>
                  </div>
                  <div className="md:p-3">
                    <h2 className="mb-3 mt-4 md:!mt-0 text-center md:!text-left  text-2xl text-white">
                      টেবিল গেম
                    </h2>
                    <p className="mb-0 text-sm text-white md:text-lg">
                      Baji999 ক্যাসিনোতে টেবিল গেমগুলি খেলোয়াড়দের একটি অনন্য
                      এবং উত্তেজনাপূর্ণ অভিজ্ঞতা প্রদান করে। ক্লাসিক থেকে আধুনিক
                      সব ধরনের স্বাদ এবং পরিসরের জন্য বেছে নেওয়ার জন্য 89টি গেম
                      রয়েছে। আপনি টিন পট্টির রোমাঞ্চ, পোকারের জটিলতা বা অন্য
                      কিছু পছন্দ করুন না কেন, আপনার শৈলীর সাথে মানানসই একটি গেম
                      অবশ্যই থাকবে।
                    </p>
                    <p className="mb-2 text-sm text-white md:text-lg">
                      Baji999-এর পরিবেশ কোনটির পিছনে নেই। আপনি যে খেলাই খেলুন না
                      কেন, আপনি নিরাপদ পরিবেশে উচ্চ-মানের বিনোদনের উপর নির্ভর
                      করতে পারেন।
                    </p>
                  </div>
                </div>

                <ul className="pl-3 list-item">
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    Bonus Mania;
                  </li>
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    WorldMatch 50s PinUp;
                  </li>
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    Arowanas Luck;
                  </li>
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    Asgardian Stones;
                  </li>
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    Big Win.
                  </li>
                </ul>

                <h2 className="mb-3 text-center md:!text-left  text-2xl text-white mt-4">
                  Baji live 999
                </h2>
                <p className="mb-0 text-sm text-white md:text-lg">
                  Baji999 ক্যাসিনোতে লাইভ গেমিং হল একটি উত্তেজনাপূর্ণ এবং
                  আনন্দদায়ক গেমিং অভিজ্ঞতার অভিজ্ঞতা নেওয়ার নিখুঁত উপায়। লাইভ
                  গেমগুলিতে, খেলোয়াড়রা অন্য খেলোয়াড়দের বিরুদ্ধে
                  প্রতিদ্বন্দ্বিতা করার সময় রিয়েল টাইমে একজন আসল ডিলার বা
                  ক্রুপিয়ারের সাথে যোগাযোগ করতে পারে। Baji999 এ উপলব্ধ লাইভ
                  গেমস এর মধ্যে রয়েছে:
                </p>
                <ul className="pl-3 my-3 list-item">
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    রুলেট
                  </li>
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    ব্ল্যাকজ্যাক
                  </li>
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    জুজু
                  </li>
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    বেকারত
                  </li>
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    অন্দর বাহার।
                  </li>
                </ul>
                <p className="mb-0 text-sm text-white md:text-lg">
                  এই সমস্ত গেমগুলি বড় জ্যাকপট এবং পুরষ্কার জেতার দুর্দান্ত
                  প্রতিকূলতার প্রস্তাব দেয়, তাই খেলোয়াড়দের জয়ের প্রচুর সুযোগ
                  থাকে! খেলোয়াড়েরা আমাদের লাইভ গেমিং টেবিলে খেলে একচেটিয়া
                  বোনাস এবং প্রচার উপভোগ করতে পারে – তাদের জয় বাড়ানোর আরেকটি
                  উপায়!
                </p>
              </div>

              <div className="border-b-[5px] border-[#353535] !py-5 mx-3">
                <h2 className="mb-3 text-2xl text-center text-white">
                  Baji999 ক্রীড়া পণ
                </h2>
                <p className="mb-4 text-sm text-white md:text-lg">
                  Baji999 বাংলাদেশের অন্যতম সেরা বেটিং হাউস। এটি সমস্ত প্রধান
                  লিগ এবং টুর্নামেন্টে বিভিন্ন ধরণের ক্রীড়া বাজির বিকল্প এবং
                  প্রতিযোগিতামূলক প্রতিকূলতা অফার করে।
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  সাইটের ব্যবহারকারী ইন্টারফেস নেভিগেট করা সহজ, ব্যবহারকারীদের
                  তারা যা খুঁজছেন তা দ্রুত খুঁজে পেতে অনুমতি দেয়। Baji999
                  ফুটবল, ক্রিকেট, বাস্কেটবল, টেনিস, ভলিবল, হকি, রাগবি সহ বাজি
                  ধরার জন্য বিস্তৃত ক্রীড়া অফার করে, এমনকি আপনি সাইবারস্পোর্ট
                  ইভেন্টগুলিতে ম্যাচগুলিও খুঁজে পাবেন। এটি ইন-প্লে বেটিং,
                  প্রাক-ম্যাচ বেটিং এবং ফিউচার মার্কেটও অফার করে।
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  Baji999-এর লক্ষ্য হল স্পোর্টস বেটিং-এর সাম্প্রতিক প্রবণতাগুলির
                  সাথে তাল মিলিয়ে চলার জন্য আপনাকে অবহিত বেটিং সিদ্ধান্ত
                  নেওয়ার জন্য প্রয়োজনীয় সমস্ত সরঞ্জামগুলি দেওয়া। আধুনিক
                  প্ল্যাটফর্মটি একটি স্বজ্ঞাত ইন্টারফেস অফার করে যা ব্যবহার করা
                  সহজ, এবং প্রচুর তথ্য এবং পরিসংখ্যান অফার করে যাতে আপনি সচেতন
                  পছন্দ করতে পারেন।
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-[url('@components/images/baji999/bg1.webp')] bg-no-repeat bg-cover">
          <div className="content-container p-[15px]">
            <h2 className="text-2xl text-center text-white">বাজিসাস অপ</h2>
            <div className="md:grid grid-cols-2 items-center mt-3 md:!mt-0">
              <div>
                <p className="text-sm text-white md:text-lg">
                  Baji999 অ্যাপটি অন্য যেকোনো স্ট্যান্ডার্ড অ্যাপের মতোই আপনার
                  অ্যান্ড্রয়েড স্মার্টফোন বা ট্যাবলেটে ইনস্টল করা আছে। একবার
                  ডাউনলোড সম্পূর্ণ হলে, গেমারদের অনেক সুবিধা দেওয়া হয়।
                  প্রধানগুলি হল একটি অ্যাকাউন্টে সীমাহীন অ্যাক্সেস এবং যে কোনও
                  জায়গায় বাজি ধরার ক্ষমতা৷ সফ্টওয়্যারটির সমস্ত সুবিধার
                  প্রশংসা করার জন্য, খেলোয়াড়দের 6টি সহজ পদক্ষেপ অনুসরণ করতে
                  হবে:
                </p>

                <ul className="list-item text-[#fff] mt-3">
                  <li className="text-sm list-disc md:text-lg">
                    অফিসিয়াল ওয়েবসাইটে যান।
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    প্রধান মেনুর নীচে “Android এর জন্য ডাউনলোড করুন” এ ক্লিক
                    করুন।
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    নির্দেশাবলী অনুসরণ করে, অ্যাপটি ইনস্টল করুন।
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    ফাইলটি চালু করুন এবং ব্যবহারকারী চুক্তিতে সম্মত হন।
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    ব্যক্তিগত মন্ত্রিসভা বা রেজিস্টারে অনুমোদন করুন।
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    অ্যাকাউন্টে জমা দিন।
                  </li>
                </ul>
                <button className="flex items-center justify-center mx-auto bg-gradient-to-r from-[#f69501] to-[#fbc004] rounded-[5px] text-sm md:text-lg h-[45px] px-[24px] text-white mt-3">
                  অ্যাপ ডাউনলোড করুন
                </button>
              </div>

              <div className="flex flex-col items-center mt-3">
                <img src={app} alt="app" className="w-[150px] md:w-[300px]" />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-[#353535]">
          <div className="content-container p-[15px] bg-[#484848] !mb-14">
            <div className="border-y-[5px] border-[#353535] !py-6 mt-4 md:mx-3">
              <h2 className="mb-3 text-2xl text-center text-white">
                Baji999 সাইটের মোবাইল সংস্করণ
              </h2>
              <p className="mb-4 text-sm text-white md:text-lg">
                স্মার্টফোন সংস্করণগুলির কার্যকারিতা কোনওভাবেই তাদের মূল সাইট বা
                মোবাইল অ্যাপের থেকে নিকৃষ্ট নয়৷ ডেস্কটপ সংস্করণ থেকে প্রধান
                পার্থক্য শুধুমাত্র ইন্টারফেসের ভিজ্যুয়াল ডিজাইনে। মেনুর
                বিভাগগুলি স্মার্টফোন এবং ট্যাবলেটগুলিতে কাজ করার জন্য অভিযোজিত
                হয়, তাই তারা কম রেজোলিউশনের স্ক্রিনেও কাজ করে।
              </p>
              <p className="mb-4 text-sm text-white md:text-lg">
                ভিজ্যুয়াল পার্থক্য হল গেমগুলির প্রধান বিভাগ এবং মেনুগুলি
                এমনভাবে সাজানো হয়েছে যাতে সেগুলি ব্যবহার করা সহজ হয়। এছাড়াও,
                ট্রাফিক বাঁচাতে, Baji999 ওয়েবসাইটের মোবাইল সংস্করণ কম বিজ্ঞাপন
                পোস্টার ব্যবহার করে, যা লোডিং গতিতে একটি স্পষ্ট সুবিধা দেয়। এই
                সমস্ত ব্যবস্থাগুলি উল্লেখযোগ্যভাবে ট্র্যাফিক খরচ কমাতে এবং
                সাইটটি দ্রুত তা নিশ্চিত করার অনুমতি দেয়।
              </p>
              <p className="mb-4 text-sm text-white md:text-lg">
                ব্যবহারকারী কার্যকারিতা কোনো ভাবেই সুবিধাবঞ্চিত হয় না. সমস্ত
                গেম এবং স্পোর্টস বেটিং চালু করা হয়েছে এবং হেঁচকি ছাড়াই কাজ
                করে৷ আধুনিক প্রযুক্তি কম স্ক্রীন রেজোলিউশনেও উচ্চ মানের ছবি
                প্রদর্শন করা সম্ভব করে তোলে। কখনও কখনও গ্রাফিক্স দৃশ্যত ডেস্কটপ
                সংস্করণের চেয়ে আরও ভাল দেখায়। যাদের ডেস্কটপ কম্পিউটার নেই বা
                যারা মোবাইল অ্যাপ ইনস্টল করতে চান না তাদের জন্য এটি সেরা বিকল্প।
              </p>
            </div>

            <div className="border-b-[5px] border-[#353535] !py-6 mt-4 md:mx-3">
              <h2 className="mb-3 text-2xl text-center text-white">
                জমা পদ্ধতি
              </h2>
              <p className="mb-4 text-sm text-white md:text-lg">
                আপনার Bangladesh Baji999 অ্যাকাউন্টে তহবিল জমা করার ক্ষেত্রে,
                আপনার কাছে বেছে নেওয়ার জন্য বেশ কয়েকটি বিকল্প রয়েছে।
                সৌভাগ্যবশত, বাংলাদেশের খেলোয়াড়দের জন্য বেশ কিছু নিরাপদ এবং
                সুরক্ষিত আমানত পদ্ধতি উপলব্ধ রয়েছে। এর মধ্যে রয়েছে:
              </p>

              <ul className="list-item text-[#fff] my-3">
                <li className="text-sm list-disc md:text-lg">
                  bKash, Rocket, Nagad
                </li>
                <li className="text-sm list-disc md:text-lg">
                  স্থানীয় ব্যাংক স্থানান্তর। খেলোয়াড়রা তাদের স্থানীয় ব্যাঙ্ক
                  অ্যাকাউন্ট থেকে সরাসরি তহবিল স্থানান্তর করতে এই পদ্ধতিটি
                  ব্যবহার করতে পারেন। এটি বাংলাদেশের খেলোয়াড়দের দ্বারা ব্যবহৃত
                  সবচেয়ে জনপ্রিয় পেমেন্ট পদ্ধতিগুলির মধ্যে একটি।
                </li>
                <li className="text-sm list-disc md:text-lg">
                  ক্রিপ্টোকারেন্সি। খেলোয়াড়রা তাদের অ্যাকাউন্টে তহবিল জমা করার
                  জন্য ক্রিপ্টোকারেন্সি যেমন টিথার ব্যবহার করতে পারে।
                </li>
              </ul>
              <p className="mb-4 text-sm text-white md:text-lg">
                বাংলাদেশ বাজি৯৯৯-এ খেলোয়াড়দের জন্য উপলব্ধ অনেকগুলি জমা পদ্ধতির
                মধ্যে এইগুলি মাত্র কয়েকটি। প্ল্যাটফর্মে সমস্ত লেনদেন সুরক্ষিত
                এবং এনক্রিপ্ট করা হয়, তাই আপনি নিশ্চিত হতে পারেন যে আপনার তহবিল
                নিরাপদ। বিপুল সংখ্যক প্রত্যাহারের বিকল্প উপলব্ধ থাকার কারণে
                আপনার জেতা প্রত্যাহার করাও সহজ। তাই খেলা শুরু করতে এবং মজা করার
                জন্য প্রস্তুত হন!
              </p>
            </div>

            <div className="border-b-[5px] border-[#353535] !py-6 mt-4 md:mx-3">
              <h2 className="mb-3 text-2xl text-center text-white">
                প্রত্যাহার
              </h2>
              <p className="mb-4 text-sm text-white md:text-lg">
                Bangladesh Baji.999-এ নিম্নলিখিত প্রত্যাহারের পদ্ধতি উপলব্ধ
                রয়েছে: বিকাশ, নগদ, রকেট এবং স্থানীয় ব্যাংক স্থানান্তর। আপনার
                অ্যাকাউন্ট থেকে টাকা তুলতে, এই পদক্ষেপগুলি অনুসরণ করুন:
              </p>

              <ul className="list-item text-[#fff] mt-3">
                <li className="text-sm list-decimal md:text-lg">
                  প্রধান ওয়েবসাইট দেখুন;
                </li>
                <li className="text-sm list-decimal md:text-lg">
                  আপনার অ্যাকাউন্টে লগ ইন করুন;
                </li>
                <li className="text-sm list-decimal md:text-lg">
                  “অ্যাকাউন্ট” বিভাগে যান;
                </li>
                <li className="text-sm list-decimal md:text-lg">
                  মেনু থেকে “প্রত্যাহার” নির্বাচন করুন;
                </li>
                <li className="text-sm list-decimal md:text-lg">
                  উপলব্ধ বিকল্পগুলির তালিকা থেকে আপনার পছন্দের অর্থপ্রদানের
                  পদ্ধতি নির্বাচন করুন;
                </li>
                <li className="text-sm list-decimal md:text-lg">
                  আপনি যে পরিমাণ প্রত্যাহার করতে চান তা লিখুন এবং “পরবর্তী”
                  ক্লিক করুন;
                </li>
                <li className="text-sm list-decimal md:text-lg">
                  স্ক্রিনে অতিরিক্ত নির্দেশাবলী অনুসরণ করুন (যেমন একটি নিরাপত্তা
                  কোড লিখুন) এবং আপনার অনুরোধ জমা দেওয়ার আগে আপনার প্রবেশ করা
                  সমস্ত বিবরণ সঠিক কিনা তা নিশ্চিত করুন;
                </li>
                <li className="text-sm list-decimal md:text-lg">
                  আপনার প্রত্যাহারের অনুরোধ প্রক্রিয়া করা হলে আপনাকে জানানো
                  হবে।
                </li>
              </ul>
              <p className="mb-4 text-sm text-white md:text-lg">
                অনুগ্রহ করে মনে রাখবেন কিছু অর্থপ্রদানের পদ্ধতি প্রক্রিয়া করতে
                বেশি সময় নিতে পারে; তবে, আপনি আপনার Baji999 অ্যাকাউন্টের
                “অ্যাকাউন্ট” বিভাগে প্রত্যাহারের স্থিতি পরীক্ষা করতে পারেন। টাকা
                তোলার জন্য, আপনাকে প্রথমে আপনার শনাক্তকরণ নথি পাঠিয়ে একটি
                অ্যাকাউন্ট শনাক্তকরণ প্রক্রিয়ার মধ্য দিয়ে যেতে হবে।
              </p>
            </div>

            <div className="border-b-[5px] border-[#353535] !py-6 mt-4 md:mx-3">
              <h2 className="mb-3 text-2xl text-center text-white">
                গেমিং প্লাটফর্ম Baji999 এর নিরাপত্তা
              </h2>
              <p className="mb-4 text-sm text-white md:text-lg">
                Baji live 999 হল একটি বাংলাদেশী বুকমেকার যা বিভিন্ন স্পোর্টস
                বেটিং এবং জুয়ার বাজারে প্রতিযোগিতামূলক প্রতিকূলতার অফার করে।
                তাদের গ্রাহকদের ডেটা এবং লেনদেনের নিরাপত্তা নিশ্চিত করতে,
                Baji.999 তাদের সার্ভার এবং ব্যবহারকারীর কম্পিউটারের মধ্যে
                প্রেরিত তথ্য এনক্রিপ্ট করতে SSL সংস্করণ 3 পদ্ধতি ব্যবহার করে। এই
                নিরাপদ এনক্রিপশন প্রযুক্তি ব্যক্তিগত তথ্য যেমন ক্রেডিট কার্ড
                নম্বর, পাসওয়ার্ড, অ্যাকাউন্টের বিবরণ ইত্যাদি সাইবার অপরাধীদের
                দ্বারা আটকানো থেকে রক্ষা করতে সাহায্য করে।
              </p>
              <p className="mb-4 text-sm text-white md:text-lg">
                এগুলি ছাড়াও, খেলার মাঠের কুরাকাও সরকারের একটি বৈধ লাইসেন্স
                রয়েছে। এর মানে হল Baji999 আইনের মধ্যে কাজ করে এবং এটি একটি
                সম্পূর্ণ আইনি অনলাইন ক্যাসিনো এবং বেটিং সাইট
              </p>
            </div>

            <div className="border-b-[5px] border-[#353535] !py-6 mt-4 md:mx-3">
              <h2 className="mb-3 text-2xl text-center text-white">
                প্রযুক্তিগত গ্রাহক সেবা
              </h2>
              <p className="mb-4 text-sm text-white md:text-lg">
                Baji 999 live গ্রাহকদের সর্বোচ্চ মানের প্রযুক্তিগত সেবা প্রদান
                করে। গেমিংয়ের সময় আপনি যে কোনও প্রযুক্তিগত সমস্যার সম্মুখীন
                হতে পারেন তার জন্য আপনাকে সাহায্য করার জন্য নিবেদিত পেশাদারদের
                একটি দল 24/7 উপলব্ধ।
              </p>
              <p className="mb-4 text-sm text-white md:text-lg">
                দলটি অনলাইন গেমিং প্রযুক্তির সমস্ত দিকগুলিতে পারদর্শী, তাই তারা
                আপনার সমস্যা দ্রুত এবং সঠিকভাবে সমাধান করতে সাহায্য করতে পারে৷
                আপনার যদি লগ ইন করতে সমস্যা হয় বা আপনার অ্যাকাউন্ট সেট আপ করতে
                সহায়তার প্রয়োজন হয়, তাহলে সমস্যা সমাধান না হওয়া পর্যন্ত
                সহায়তা আপনাকে প্রতিটি প্রক্রিয়ার ধাপে ধাপে নিয়ে যেতে পারে।
                আপনি লাইভ চ্যাট, ইমেলের মাধ্যমে যোগাযোগ করতে পারেন বা আপনি
                ফেসবুক বা হোয়াটসঅ্যাপের মতো সোশ্যাল মিডিয়াতে লিখতে পারেন।
              </p>
              <p className="mb-4 text-sm text-white md:text-lg">
                Baji999 এ, গ্রাহক পরিষেবা গেমপ্লের একটি গুরুত্বপূর্ণ অংশ এবং
                তারা এটিকে খুব গুরুত্ব সহকারে নেয়। আপনার যেকোন প্রশ্ন বা
                সমস্যায় সাহায্য করার জন্য দলটি আছে। আপনার যদি কখনও সমস্যা হয়,
                আমাদের সাথে যোগাযোগ করতে দ্বিধা করবেন না – আমরা সাহায্য করতে
                পেরে খুশি হব! এছাড়াও প্রায়শই জিজ্ঞাসিত প্রশ্নের একটি বিস্তৃত
                তালিকা রয়েছে, যেখানে আপনি আপনার সময় বাঁচাতে আপনার প্রশ্নের
                উত্তর পেতে পারেন।
              </p>
            </div>

            <div className="!py-6 mt-4 md:mx-3">
              <h2 className="mb-3 text-2xl text-white">সচরাচর জিজ্ঞাস্য</h2>
              <h2 className="mb-3 text-2xl text-white">
                আমি কিভাবে বাজি লাইভ 999 ক্যাসিনোতে যোগ দেব?
              </h2>
              <p className="mb-4 text-sm text-white md:text-lg">
                Baji999 ক্যাসিনোতে যোগদান করা খুবই সহজ! আপনাকে যা করতে হবে তা হল
                আপনার ব্যক্তিগত বিবরণ প্রবেশ করান এবং একটি ব্যবহারকারীর নাম এবং
                পাসওয়ার্ড চয়ন করে একটি অ্যাকাউন্ট তৈরি করুন৷ আপনার অ্যাকাউন্ট
                তৈরি হওয়ার সাথে সাথে আপনি এখনই খেলা শুরু করতে পারেন। আপনি
                অতিরিক্ত খেলার অর্থের জন্য আমাদের নিরাপদ অর্থপ্রদান সমাধানগুলির
                একটি ব্যবহার করে আপনার অ্যাকাউন্টে একটি জমা করতে পারেন।
              </p>
              <h2 className="mb-3 text-2xl text-white">
                Baji999 ক্যাসিনোতে আমার লেনদেন কি নিরাপদ?
              </h2>
              <p className="mb-4 text-sm text-white md:text-lg">
                একেবারেই! আপনার সমস্ত লেনদেন নিরাপদ এবং নিরাপদ তা নিশ্চিত করতে
                আমরা সর্বশেষ এনক্রিপশন প্রযুক্তি ব্যবহার করি। আপনার ব্যক্তিগত
                এবং আর্থিক তথ্য সবসময় গোপন রাখা হয় এবং কারো সাথে শেয়ার করা
                হয় না।
              </p>
              <h2 className="mb-3 text-2xl text-white">
                কোন বোনাস অফার উপলব্ধ আছে?
              </h2>
              <p className="mb-4 text-sm text-white md:text-lg">
                হ্যাঁ, আপনি সঠিক জায়গায় এসেছেন! Baji999 নতুন এবং বিদ্যমান
                খেলোয়াড়দের জন্য একইভাবে অবিশ্বাস্য বোনাস এবং প্রচার অফার করে।
                বোনাসগুলি নিয়মিত আপডেট করা হয়, তাই সাম্প্রতিক অফারগুলির জন্য
                সাইটে নজর রাখুন৷ এছাড়াও আপনি আমাদের সমস্ত প্রচার এবং বিশেষ
                অফারগুলিতে নিয়মিত আপডেট পেতে নিউজলেটারে সাইন আপ করতে পারেন৷
              </p>
              <h2 className="mb-3 text-2xl text-white">
                Baji999 ক্যাসিনো দ্বারা গৃহীত অর্থপ্রদানের পদ্ধতিগুলি কী কী?
              </h2>
              <p className="mb-4 text-sm text-white md:text-lg">
                Baji999 স্থানীয় ব্যাঙ্ক স্থানান্তর, ই-ওয়ালেট এবং
                ক্রিপ্টোকারেন্সি সহ বিভিন্ন উপায়ে অর্থপ্রদান গ্রহণ করে। গৃহীত
                অর্থপ্রদানের পদ্ধতি সম্পর্কে আরও জানতে, প্রধান ওয়েবসাইটে
                “পেমেন্ট পদ্ধতি” অনুসন্ধান করুন৷
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withLocation(Baji999);
